import { Buffer } from 'buffer'

import axios from 'axios'
import { Field, FieldProps, Form, Formik, getIn, setIn } from 'formik'
import Cookies from 'js-cookie'
import React, { useEffect, useState, ReactNode, useCallback } from 'react'
import AnimateHeight from 'react-animate-height'
import { Options } from 'react-select'

import { OptionType, SearchForm } from './Search.types'
import Button from '../Button/Button'
import { CloseFiltersIcon, CrossIcon, FiltersIcon, HouseIcon, PinIcon } from '../icons'
import { loadOptions } from '../selects/FormSelect/FormSelect.types'
import { LocationSelect } from '../selects/LocationSelect'
import SearchFormSelect from '../selects/SearchFormSelect'
import { uniqueArray } from '../utils/uniqueArray'
import UrlParser from '../utils/UrlParser'
import useCustomCompareMemo from '../utils/useCustomCompareMemo'
import '../styles/search-main.scss'


export const breakpoint = window.matchMedia('(min-width: 800px)')

const api = (url: string) => `/v2/ajax${url}`

interface Currencies {
  [key: string]: string
}

const currencies: Currencies[] = [
  {
    symbol: 'R',
    code: 'ZAR',
    decription: 'South african rand'
  },
  {
    symbol: '$',
    code: 'USD',
    decription: 'United states dollar'
  },
  {
    symbol: '£',
    code: 'GBP',
    decription: 'Pound sterling'
  },
  {
    symbol: '€',
    code: 'EUR',
    decription: 'Euro'
  },
  {
    symbol: '₨',
    code: 'MUR',
    decription: 'Mauritius rupee'
  },
  {
    symbol: 'P',
    code: 'BWP',
    decription: 'Botswana pula'
  },
  {
    symbol: 'KSh',
    code: 'KES',
    decription: 'Kenyan shilling'
  },
  {
    symbol: 'MT',
    code: 'MZN',
    decription: 'Mozambique metical'
  },
  {
    symbol: '$',
    code: 'NAD',
    decription: 'Namibian dollar'
  },
  {
    symbol: 'L',
    code: 'SZL',
    decription: 'Swaziland lilangeni'
  },
  {
    symbol: 'ZK',
    code: 'ZMW',
    decription: 'Zambian kwacha'
  },
  {
    symbol: '₨',
    code: 'SCR',
    decription: 'Seychelles rupee'
  }
]

type Categories = {
  'For Sale': Map<string, number>;
  'To Let': Map<string, number>;
};

const categories: Categories = {
  'For Sale': new Map<string, number>(),
  'To Let': new Map<string, number>()
}

const CATEGORIES = [
  'Residential',
  'Residential Development',
  'Residential Estate',
  'Residential Vacant Land',
  'Commercial',
  'Commercial and Industrial Development',
  'Commercial Estate',
  'Commercial & Industrial Vacant Land',
  'Industrial',
  'Mixed Use',
  'Retail',
  'Agricultural',
  'Farms & Small Holdings',
  'Tenders',
  'Auction',
  'Bank Repossessed',
  'Bank Assisted',
  'Auctions',
  'Holiday Accommodation',
  'Student Accommodation'
]

let buySelectOptions: OptionType<number>[] = []

let countTimer: ReturnType<typeof setTimeout>

let propertyTimer: ReturnType<typeof setTimeout>

const csrftoken = Cookies.get('csrftoken')

// eslint-disable-next-line import/no-named-as-default-member
let source = axios.CancelToken.source()

const initializeSearch = () => axios.post(api('/setup-search/'), {}, { headers: { 'X-CSRFToken': csrftoken } }).then(resp => {
  window.searchData = resp.data
  Object.keys(window.searchData).forEach(key => {
    let c_key: string
    if ([
      'For Sale'
    ].includes(key)) {
      c_key = 'For Sale'
      buySelectOptions.push({ value: c_key, label: c_key })
    }
    if ([
      'To Let'
    ].includes(key)) {
      c_key = 'To Let'
      buySelectOptions.push({ value: c_key, label: c_key })
    }
    buySelectOptions = uniqueArray(buySelectOptions, 'value').sort((a, b) => [ 'For Sale', 'To Let' ].indexOf(b) - [ 'For Sale', 'To Let' ].indexOf(a))
    getIn(window.searchData, key).forEach(listing_type => {
      categories[c_key as keyof Categories].set(listing_type, 1)
    })
  })
  return window.searchData
}).catch(e => console.error(e))


const fetchPropertyTypes = (data: SearchForm) => axios.post(api('/property-types/'), data, { headers: { 'X-CSRFToken': csrftoken } })

const buyRentMap = {
  Buy: 'For Sale',
  Rent: 'To Let'
}


let defaultCategory = 'Residential'
if (window.request.search_defaults?.category) {
  defaultCategory = window.request.search_defaults?.category
}
if (window.request.params?.sub) {
  const { sub, area, suburb, property_type, listing_type } = window.request.params
  switch (sub) {
    case 'residential':
      defaultCategory = 'Residential'
      if (property_type === 'vacant-land' && area === 'all' && suburb === 'all') {
        defaultCategory = 'Residential Vacant Land'
      }
      break
    case 'commercial':
      defaultCategory = 'Commercial'
      if (property_type === 'vacant-land' && area === 'all' && suburb === 'all') {
        defaultCategory = 'Commercial & Industrial Vacant Land'
      }
      break
    case 'industrial':
      defaultCategory = 'Industrial'
      break
    case 'retail':
      defaultCategory = 'Retail'
      break
    case 'agricultural':
      defaultCategory = 'Agricultural'
      break
    case 'mixed-use':
      defaultCategory = 'Mixed Use'
      break
    case 'farms-and-small-holdings':
      defaultCategory = 'Farms & Small Holdings'
      break
    case 'new-development':
      if (listing_type === 'residential') {
        defaultCategory = 'Residential Development'
      } else {
        defaultCategory = 'Commercial & Industrial New Development'
      }
      break
    case 'estate':
      if (listing_type === 'residential') {
        defaultCategory = 'Residential Estate'
      } else {
        defaultCategory = 'Commercial & Industrial Estate'
      }
      break
    case 'holiday':
      defaultCategory = 'Holiday Accommodation'
      break
    case 'residential-vacant-land':
      defaultCategory = 'Residential Vacant Land'
      break
    case 'commercial-vacant-land':
      defaultCategory = 'Commercial & Industrial Vacant Land'
      break
    default:
      defaultCategory = window.request.search_defaults ? window.request.search_defaults.category : 'Residential'
  }
}

let initialForm: SearchForm = {
  buy_rent: getIn(buyRentMap, window.request?.search_defaults?.buy_rent, 'For Sale'),
  category: defaultCategory,
  locations: [],
  property_type__in: []
}

if (window.request.previous_search) {
  initialForm = window.request.previous_search
}
const local_search = window.localStorage.getItem('pd_search')
if (local_search) {
  initialForm = JSON.parse(local_search)
}


initialForm.currency = window.request.previous_search?.currency as string || window.CURRENCY


if (initialForm.category?.includes('&amp;')) {
  initialForm.category = initialForm.category.replace('&amp;', '&')
}

const urlParser = new UrlParser(`${window.location.pathname}${window.location.search}`)

const prefillInitialForm = async (form: SearchForm) => {
  if (!window.location.pathname.includes('/results')) { return form }

  let prefilledForm = { ...form }
  if (getIn(window, 'request.search_params')) {
    const decodedRequestBodyString = Buffer.from(window.request.search_params, 'base64')
    const requestBodyObject = JSON.parse(decodedRequestBodyString.toString())
    prefilledForm = requestBodyObject
  }
  const queryValues = urlParser.queryData
  const { sub, listing_type, area, suburb, property_type } = urlParser.parsedValues.groups
  if (window.request.search_defaults.branch__in) {
    prefilledForm.branch__in = window.request.search_defaults.branch__in
  }

  if (listing_type === 'for-sale') {
    prefilledForm.buy_rent = 'For Sale'
  }

  if (listing_type === 'to-let') {
    prefilledForm.buy_rent = 'To Let'
  }

  if (prefilledForm.category?.includes('&amp;')) {
    prefilledForm.category = prefilledForm.category.replace('&amp;', '&')
  }

  let parsedCategoryId
  let parsedCategory = ''
  switch (sub) {
    case 'residential':
      parsedCategory = 'Residential'
      if (property_type === 'vacant-land' && area === 'all' && suburb === 'all') {
        parsedCategory = 'Residential Vacant Land'
      }
      break
    case 'commercial':
      parsedCategory = 'Commercial'
      if (property_type === 'vacant-land' && area === 'all' && suburb === 'all') {
        parsedCategory = 'Commercial & Industrial Vacant Land'
      }
      break
    case 'industrial':
      parsedCategory = 'Industrial'
      break
    case 'retail':
      parsedCategory = 'Retail'
      break
    case 'agricultural':
      parsedCategory = 'Agricultural'
      break
    case 'mixed-use':
      parsedCategory = 'Mixed Use'
      break
    case 'farms-and-small-holdings':
      parsedCategory = 'Farms & Small Holdings'
      break
    case 'new-development':
      if (listing_type === 'residential') {
        parsedCategory = 'Residential Development'
      } else {
        parsedCategory = 'Commercial & Industrial New Development'
      }
      break
    case 'estate':
      if (listing_type === 'residential') {
        parsedCategory = 'Residential Estate'
      } else {
        parsedCategory = 'Commercial & Industrial Estate'
      }
      break
    case 'holiday':
      prefilledForm.buy_rent = 'Rent'
      parsedCategory = 'Holiday Accommodation'
      break
    case 'residential-vacant-land':
      parsedCategory = 'Residential Vacant Land'
      break
    case 'commercial-vacant-land':
      parsedCategory = 'Commercial & Industrial Vacant Land'
      break
    default:
      parsedCategory = window.request.search_defaults ? window.request.search_defaults.category : 'Residential'
  }

  if (categories[prefilledForm.buy_rent as keyof Categories].has(parsedCategory)) {
    parsedCategoryId = parsedCategory
  } else {
    parsedCategoryId = categories[prefilledForm.buy_rent as keyof Categories][0]
  }
  // const locationsMeta = queryValues.locations_meta
  const minPrice = queryValues.min_price
  const maxPrice = queryValues.max_price
  const sizeFrom = queryValues.size_from
  const sizeTo = queryValues.size_to
  const minBeds = queryValues.min_beds

  const selectedArea = getIn(window.request.params, 'area')
  const selectedSuburb = getIn(window.request.params, 'suburb')
  const selectedPropertyTypes = getIn(window.request.params, 'property_type__in', []) || []

  if (selectedArea && selectedArea !== 'all' && !selectedSuburb) {
    try {
      const result = await axios.post(api('/location-search/'), { ...prefilledForm, term: selectedArea.split('-').join(' ') }, { headers: { 'X-CSRFToken': csrftoken } })
      if (result.data) {
        const match = result.data[0]
        prefilledForm.locations.push({
          value: `{"id": ${match.id}, "type": "${match.type}"}`,
          id: match.id,
          name: match.label,
          label: match.name,
          type: match.type
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  if (selectedSuburb && selectedSuburb !== 'all') {
    try {
      const result = await axios.post(api('/location-search/'), { ...prefilledForm, term: selectedSuburb.split('-').join(' ') }, { headers: { 'X-CSRFToken': csrftoken } })
      if (result.data) {
        const match = result.data[0]
        prefilledForm.locations.push({
          value: `{"id": ${match.id}, "type": "${match.type}"}`,
          id: match.id,
          name: match.label,
          label: match.name,
          type: match.type
        })
      }
    } catch (e) {
      console.error(e)
    }
  }
  prefilledForm.locations = uniqueArray(prefilledForm.locations, 'value')

  if (selectedPropertyTypes.length) {
    // eslint-disable-next-line max-len
    prefilledForm.property_type__in = prefilledForm.property_type__in ? uniqueArray([
      ...prefilledForm.property_type__in,
      ...selectedPropertyTypes
    ]) : uniqueArray([ ...selectedPropertyTypes ])
  }
  if (!prefilledForm.category || !categories[prefilledForm.buy_rent as keyof Categories].has(prefilledForm.category)) {
    prefilledForm.category = parsedCategoryId
  }

  // prefilledForm.locations = decodeURIComponent(locationsMeta).split(',')

  // prefilledForm.property_type__in = decodeURIComponent(propertyTypes).split('|')

  prefilledForm.min_price = minPrice ? Number(decodeURIComponent(minPrice)) : undefined

  prefilledForm.max_price = maxPrice ? Number(decodeURIComponent(maxPrice)) : undefined

  prefilledForm.size_from = sizeFrom ? Number(decodeURIComponent(sizeFrom)) : undefined

  prefilledForm.size_to = sizeTo ? Number(decodeURIComponent(sizeTo)) : undefined

  prefilledForm.min_beds = minBeds ? String(Number(decodeURIComponent(minBeds))) : undefined

  return await prefilledForm
}

interface InputFieldProps extends FieldProps {
  label?: string
  className?: string
  id?: string
  name: string
  type: string
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const InputField = ({ className, label, field, form, ...props }: InputFieldProps): ReactNode => (
  <div className={`${className} field-${field.name}`}>
    <div className="field__wrapper">
      <label className={`${className}__label`} htmlFor={props.id ? props.id : `id_${field.name}`}>{label}</label>
      <input {...field} {...props} id={props.id ? props.id : `id_${field.name}`} />
    </div>
  </div>
)

const minBedsOptions: OptionType<number>[] = [
  { value: '0', label: 'Any' },
  { value: '1', label: '1+' },
  { value: '2', label: '2+' },
  { value: '3', label: '3+' },
  { value: '4', label: '4+' },
  { value: '5', label: '5+' }
]

const anyYesNo: OptionType<boolean>[] = [
  { value: '', label: 'Any' },
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
]


const getPostData = (form: SearchForm): SearchForm => {
  delete form.flatlet
  Object.keys(form).forEach(k => {
    if (form[k] === '') {
      delete form[k]
    }
  })
  return form
}

export const Search = (): React.ReactNode => {
  const [ initialized, setInitialized ] = useState(false)
  const [ isMobile, setIsMobile ] = useState(!breakpoint.matches)
  const [ initvals, setInitvals ] = useState(initialForm)
  const checkMobile = useCallback(e => {
    setIsMobile(!e.matches)
  }, [ isMobile ])

  useEffect(() => {
    breakpoint.addEventListener('change', checkMobile)
    return () => {
      breakpoint.removeEventListener('change', checkMobile)
    }
  }, [])
  useEffect(() => {
    if (!initialized) {
      initializeSearch().then(async () => {
        setInitvals(await prefillInitialForm(initialForm))
        setInitialized(true)
      })
    }
  }, [ initialized ])
  return (
    <Formik
      initialValues={initvals}
      enableReinitialize
      onSubmit={(values: SearchForm) => {
        const values_string = JSON.stringify(values)
        const encodedString = Buffer.from(values_string).toString('base64')
        const form = document.createElement('form')
        window.localStorage.setItem('pd_search', values_string)
        form.setAttribute('method', 'POST')
        form.setAttribute('action', '/results/setup-search-results/')
        const input = document.createElement('input')
        const valuesInput = document.createElement('input')
        const token = document.createElement('input')
        valuesInput.setAttribute('name', 'search_values')
        valuesInput.setAttribute('value', encodedString)
        input.setAttribute('name', 'search_params')
        input.setAttribute('value', encodedString)
        token.setAttribute('name', 'csrfmiddlewaretoken')
        token.setAttribute('value', csrftoken || '')
        form.appendChild(input)
        form.appendChild(valuesInput)
        form.appendChild(token)
        document.body.appendChild(form)
        form.submit()
      }}
    >{props => {
        const { values } = props
        const [ showFilters, setShowFilters ] = useState(false)

        const [ selectedPropertyTypes, setSelectedPropertyTypes ] = useState([])

        const [ propertyCount, setPropertyCount ] = useState<number>(0)
        const {
          buy_rent,
          category,
          locations,
          ...filtered_values
        } = values

        const categoryTypeOptions = {
          'For Sale': {
            Residential: [
              {
                name: 'Property Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  },
                  {
                    name: 'has_flat_or_accomm',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Flatlet / Domestic Quarters</label>,
                    defaultValue: initvals.has_flat_or_accomm,
                    options: anyYesNo
                  }
                ]
              },
              {
                name: 'Bed & Bath',
                fields: [
                  {
                    name: 'bedrooms__gte',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Bedrooms</label>,
                    defaultValue: initvals.bedrooms__gte || '0',
                    options: minBedsOptions
                  },
                  {
                    name: 'bathrooms__gte',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Bathrooms</label>,
                    defaultValue: initvals.bathrooms__gte || '0',
                    options: minBedsOptions
                  }
                ]
              },
              {
                name: 'Parking',
                fields: [
                  {
                    name: 'parking_type',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Type</label>,
                    defaultValue: initvals.parking_type || 'any',
                    options: [
                      { value: 'any', label: 'Any' },
                      { value: 'garages', label: 'Garages' },
                      { value: 'carports', label: 'Carports' }
                    ]
                  },
                  {
                    name: 'parking_type__gte',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Spaces</label>,
                    defaultValue: initvals.parking_type__gte || '0',
                    options: minBedsOptions
                  }
                ]
              },
              {
                name: 'Other',
                fields: [
                  {
                    name: 'pets_allowed',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Pets</label>,
                    defaultValue: initvals.pets_allowed,
                    options: anyYesNo
                  },
                  {
                    name: 'pool',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Pool</label>,
                    defaultValue: initvals.pool,
                    options: anyYesNo
                  }
                ]
              },
              {
                fields: [
                  {
                    name: 'garden',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Garden</label>,
                    defaultValue: initvals.garden,
                    options: anyYesNo
                  },
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              }
            ],
            Commercial: [
              {
                name: 'Property Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  }
                ]
              },
              {
                name: 'Other',
                fields: [
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              }
            ],
            Industrial: [
              {
                name: 'Property Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  }
                ]
              },
              {
                name: 'Other',
                fields: [
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              }
            ],
            Retail: [
              {
                name: 'Property Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  }
                ]
              },
              {
                name: 'Other',
                fields: [
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              }
            ],
            Agricultural: [
              {
                name: 'Property Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  }
                ]
              },
              {
                name: 'Other',
                fields: [
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              }
            ],
            'Mixed Use': [
              {
                name: 'Property Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  }
                ]
              },
              {
                name: 'Other',
                fields: [
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              }
            ],
            'Residential Vacant Land': [
              {
                name: 'Size',
                fields: [
                  {
                    name: 'size_from',
                    component: InputField,
                    className: 'input-field',
                    label: 'From',
                    defaultValue: initvals.size_from,
                    options: anyYesNo
                  },
                  {
                    name: 'size_to',
                    component: InputField,
                    className: 'input-field',
                    label: 'To',
                    defaultValue: initvals.size_to,
                    options: anyYesNo
                  }
                ]
              }
            ],
            'Commercial & Industrial Vacant Land': [
              {
                name: 'Size',
                fields: [
                  {
                    name: 'size_from',
                    component: InputField,
                    className: 'input-field',
                    label: 'From',
                    defaultValue: initvals.size_from,
                    options: anyYesNo
                  },
                  {
                    name: 'size_to',
                    component: InputField,
                    className: 'input-field',
                    label: 'To',
                    defaultValue: initvals.size_to,
                    options: anyYesNo
                  }
                ]
              }
            ],
            'Farms & Small Holdings': [
              {
                name: 'Property Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  },
                  {
                    name: 'has_flat_or_accomm',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Flatlet / Domestic Quarters</label>,
                    defaultValue: initvals.has_flat_or_accomm,
                    options: anyYesNo
                  }
                ]
              },
              {
                name: 'Size',
                fields: [
                  {
                    name: 'size_from',
                    component: InputField,
                    className: 'input-field',
                    label: 'From',
                    defaultValue: initvals.size_from,
                    options: anyYesNo
                  },
                  {
                    name: 'size_to',
                    component: InputField,
                    className: 'input-field',
                    label: 'To',
                    defaultValue: initvals.size_to,
                    options: anyYesNo
                  }
                ]
              }
            ],
            'Residential Development': [
              {
                name: 'Development Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  }
                ]
              },
              {
                name: 'Other',
                fields: [
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              }
            ],
            'Commercial & Industrial Development': [
              {
                name: 'Development Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  }
                ]
              },
              {
                name: 'Other',
                fields: [
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              }
            ],
            'Residential Estate': [
              {
                name: 'Estate Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  }
                ]
              },
              {
                name: 'Other',
                fields: [
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              }
            ],
            'Commercial & Industrial Estate': [
              {
                name: 'Estate Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  }
                ]
              },
              {
                name: 'Other',
                fields: [
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              }
            ],
            Auction: [
              {
                name: 'Property Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  }
                ]
              },
              {
                name: 'Other',
                fields: [
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              }
            ],
            'Bank Assisted': [
              {
                name: 'Property Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  }
                ]
              },
              {
                name: 'Other',
                fields: [
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              }
            ],
            'Bank Repossessed': [
              {
                name: 'Property Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  }
                ]
              },
              {
                name: 'Other',
                fields: [
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              }
            ]
          },
          'To Let': {
            Residential: [
              {
                name: 'Property Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  },
                  {
                    name: 'has_flat_or_accomm',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Flatlet / Domestic Quarters</label>,
                    defaultValue: initvals.has_flat_or_accomm,
                    options: anyYesNo
                  }
                ]
              },
              {
                name: 'Bed & Bath',
                fields: [
                  {
                    name: 'bedrooms__gte',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Bedrooms</label>,
                    defaultValue: initvals.bedrooms__gte || '0',
                    options: minBedsOptions
                  },
                  {
                    name: 'bathrooms__gte',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Bathrooms</label>,
                    defaultValue: initvals.bathrooms__gte || '0',
                    options: minBedsOptions
                  }
                ]
              },
              {
                name: 'Parking',
                fields: [
                  {
                    name: 'parking_type',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Type</label>,
                    defaultValue: initvals.parking_type || 'any',
                    options: [
                      { value: 'any', label: 'Any' },
                      { value: 'garages', label: 'Garages' },
                      { value: 'carports', label: 'Carports' }
                    ]
                  },
                  {
                    name: 'parking_type__gte',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Spaces</label>,
                    defaultValue: initvals.parking_type__gte || '0',
                    options: minBedsOptions
                  }
                ]
              },
              {
                name: 'Other',
                fields: [
                  {
                    name: 'pets_allowed',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Pets</label>,
                    defaultValue: initvals.pets_allowed,
                    options: anyYesNo
                  },
                  {
                    name: 'pool',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Pool</label>,
                    defaultValue: initvals.pool,
                    options: anyYesNo
                  }
                ]
              },
              {
                fields: [
                  {
                    name: 'garden',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Garden</label>,
                    defaultValue: initvals.garden,
                    options: anyYesNo
                  },
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              }
            ],
            Commercial: [
              {
                name: 'Property Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  },
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              },
              {
                name: 'Size',
                fields: [
                  {
                    name: 'size_from',
                    component: InputField,
                    className: 'input-field',
                    label: 'From',
                    defaultValue: initvals.size_from,
                    options: anyYesNo
                  },
                  {
                    name: 'size_to',
                    component: InputField,
                    className: 'input-field',
                    label: 'To',
                    defaultValue: initvals.size_to,
                    options: anyYesNo
                  }
                ]
              },
              {
                name: 'Price per month',
                fields: [
                  {
                    name: 'gross_monthly_rental__gte',
                    component: InputField,
                    className: 'input-field',
                    label: 'Min',
                    defaultValue: initvals.gross_monthly_rental__gte,
                    options: anyYesNo
                  },
                  {
                    name: 'gross_monthly_rental__lte',
                    component: InputField,
                    className: 'input-field',
                    label: 'Max',
                    defaultValue: initvals.gross_monthly_rental__lte,
                    options: anyYesNo
                  }
                ]
              }
            ], Industrial: [
              {
                name: 'Property Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  },
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              },
              {
                name: 'Size',
                fields: [
                  {
                    name: 'size_from',
                    component: InputField,
                    className: 'input-field',
                    label: 'From',
                    defaultValue: initvals.size_from,
                    options: anyYesNo
                  },
                  {
                    name: 'size_to',
                    component: InputField,
                    className: 'input-field',
                    label: 'To',
                    defaultValue: initvals.size_to,
                    options: anyYesNo
                  }
                ]
              },
              {
                name: 'Price per month',
                fields: [
                  {
                    name: 'gross_monthly_rental__gte',
                    component: InputField,
                    className: 'input-field',
                    label: 'Min',
                    defaultValue: initvals.gross_monthly_rental__gte,
                    options: anyYesNo
                  },
                  {
                    name: 'gross_monthly_rental__lte',
                    component: InputField,
                    className: 'input-field',
                    label: 'Max',
                    defaultValue: initvals.gross_monthly_rental__lte,
                    options: anyYesNo
                  }
                ]
              }
            ],
            Retail: [
              {
                name: 'Property Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  },
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              },
              {
                name: 'Size',
                fields: [
                  {
                    name: 'size_from',
                    component: InputField,
                    className: 'input-field',
                    label: 'From',
                    defaultValue: initvals.size_from,
                    options: anyYesNo
                  },
                  {
                    name: 'size_to',
                    component: InputField,
                    className: 'input-field',
                    label: 'To',
                    defaultValue: initvals.size_to,
                    options: anyYesNo
                  }
                ]
              },
              {
                name: 'Price per month',
                fields: [
                  {
                    name: 'gross_monthly_rental__gte',
                    component: InputField,
                    className: 'input-field',
                    label: 'Min',
                    defaultValue: initvals.gross_monthly_rental__gte,
                    options: anyYesNo
                  },
                  {
                    name: 'gross_monthly_rental__lte',
                    component: InputField,
                    className: 'input-field',
                    label: 'Max',
                    defaultValue: initvals.gross_monthly_rental__lte,
                    options: anyYesNo
                  }
                ]
              }
            ],
            Agricultural: [
              {
                name: 'Property Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  },
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              },
              {
                name: 'Size',
                fields: [
                  {
                    name: 'size_from',
                    component: InputField,
                    className: 'input-field',
                    label: 'From',
                    defaultValue: initvals.size_from,
                    options: anyYesNo
                  },
                  {
                    name: 'size_to',
                    component: InputField,
                    className: 'input-field',
                    label: 'To',
                    defaultValue: initvals.size_to,
                    options: anyYesNo
                  }
                ]
              },
              {
                name: 'Price per month',
                fields: [
                  {
                    name: 'gross_monthly_rental__gte',
                    component: InputField,
                    className: 'input-field',
                    label: 'Min',
                    defaultValue: initvals.gross_monthly_rental__gte,
                    options: anyYesNo
                  },
                  {
                    name: 'gross_monthly_rental__lte',
                    component: InputField,
                    className: 'input-field',
                    label: 'Max',
                    defaultValue: initvals.gross_monthly_rental__lte,
                    options: anyYesNo
                  }
                ]
              }
            ],
            'Mixed Use': [
              {
                name: 'Property Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  },
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              },
              {
                name: 'Size',
                fields: [
                  {
                    name: 'size_from',
                    component: InputField,
                    className: 'input-field',
                    label: 'From',
                    defaultValue: initvals.size_from,
                    options: anyYesNo
                  },
                  {
                    name: 'size_to',
                    component: InputField,
                    className: 'input-field',
                    label: 'To',
                    defaultValue: initvals.size_to,
                    options: anyYesNo
                  }
                ]
              },
              {
                name: 'Price per month',
                fields: [
                  {
                    name: 'gross_monthly_rental__gte',
                    component: InputField,
                    className: 'input-field',
                    label: 'Min',
                    defaultValue: initvals.gross_monthly_rental__gte,
                    options: anyYesNo
                  },
                  {
                    name: 'gross_monthly_rental__lte',
                    component: InputField,
                    className: 'input-field',
                    label: 'Max',
                    defaultValue: initvals.gross_monthly_rental__lte,
                    options: anyYesNo
                  }
                ]
              }
            ],
            'Farms & Small Holdings': [
              {
                name: 'Property Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  },
                  {
                    name: 'has_flat_or_accomm',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Flatlet / Domestic Quarters</label>,
                    defaultValue: initvals.has_flat_or_accomm,
                    options: anyYesNo
                  }
                ]
              },
              {
                name: 'Size',
                fields: [
                  {
                    name: 'size_from',
                    component: InputField,
                    className: 'input-field',
                    label: 'From',
                    defaultValue: initvals.size_from,
                    options: anyYesNo
                  },
                  {
                    name: 'size_to',
                    component: InputField,
                    className: 'input-field',
                    label: 'To',
                    defaultValue: initvals.size_to,
                    options: anyYesNo
                  }
                ]
              }
            ],
            'Student Accommodation': [
              {
                name: 'Property Type',
                fields: [
                  {
                    name: 'property_type__in',
                    component: SearchFormSelect,
                    truncate: 1,
                    placeholder: 'Property Type',
                    isMulti: true,
                    options: selectedPropertyTypes
                  },
                  {
                    name: 'has_flat_or_accomm',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Flatlet / Domestic Quarters</label>,
                    defaultValue: initvals.has_flat_or_accomm,
                    options: anyYesNo
                  }
                ]
              },
              {
                name: 'Bed & Bath',
                fields: [
                  {
                    name: 'bedrooms__gte',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Bedrooms</label>,
                    defaultValue: initvals.bedrooms__gte || '0',
                    options: minBedsOptions
                  },
                  {
                    name: 'bathrooms__gte',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Bathrooms</label>,
                    defaultValue: initvals.bathrooms__gte || '0',
                    options: minBedsOptions
                  }
                ]
              },
              {
                name: 'Parking',
                fields: [
                  {
                    name: 'parking_type',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Type</label>,
                    defaultValue: initvals.parking_type || 'any',
                    options: [
                      { value: 'any', label: 'Any' },
                      { value: 'garages', label: 'Garages' },
                      { value: 'carports', label: 'Carports' }
                    ]
                  },
                  {
                    name: 'parking_type__gte',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Spaces</label>,
                    defaultValue: initvals.parking_type__gte || '0',
                    options: minBedsOptions
                  }
                ]
              },
              {
                name: 'Other',
                fields: [
                  {
                    name: 'pets_allowed',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Pets</label>,
                    defaultValue: initvals.pets_allowed,
                    options: anyYesNo
                  },
                  {
                    name: 'pool',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Pool</label>,
                    defaultValue: initvals.pool,
                    options: anyYesNo
                  }
                ]
              },
              {
                fields: [
                  {
                    name: 'garden',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">Garden</label>,
                    defaultValue: initvals.garden,
                    options: anyYesNo
                  },
                  {
                    name: 'on_show',
                    component: SearchFormSelect,
                    icon: <label className="input-field__label">On Show</label>,
                    defaultValue: initvals.on_show,
                    options: anyYesNo
                  }
                ]
              }
            ]
          }
        }

        const [ typeOptions, setTypeOptions ] = useState(getIn(categoryTypeOptions, `${buy_rent}.${category}`))

        let is_filtered = false
        typeOptions?.forEach(group => {
          group.fields.forEach(field => {
            const foundValue = getIn(filtered_values, field.name)
            if (Array.isArray(foundValue)) {
              if (foundValue.length) {
                is_filtered = true
              }
            } else if (field.defaultValue && foundValue !== field.defaultValue) {
              is_filtered = true
            } else if (!field.defaultValue && foundValue) {
              is_filtered = true
            }
          })
        })

        const fetchLocations: loadOptions = async (inputValue): Promise<Options<OptionType<string>>> => {
          if (!inputValue) {
            return []
          }
          try {
            const results = await axios.post(api('/location-search/'), { ...values, term: inputValue }, { headers: { 'X-CSRFToken': csrftoken } })
            return results.data.map(result => ({
              value: `{"id": ${result.id}, "type": "${result.type}"}`,
              id: result.id,
              name: result.label,
              label: result.name,
              type: result.type
            })) as unknown as Options<OptionType<string>>
          } catch (e) {
            console.error(e)
          }
          return []
        }

        const categoriesOptions: OptionType<string>[] = window.searchData && buy_rent ? (
          uniqueArray(getIn(window.searchData, buy_rent, []) || []).map(listing_type => ({
            value: listing_type, label: listing_type
          })).sort((a, b) => CATEGORIES.indexOf(a.value) - CATEGORIES.indexOf(b.value))
        ) : []

        const resetAdvanced = (additionalProps: SearchForm) => {
          setTimeout(() => {
            props.setValues(prevState => (
              {
                ...prevState,
                ...additionalProps,
                pets_allowed: '',
                on_show: '',
                furnished: '',
                has_flat_or_accomm: '',
                parking_type: [ 'Residential' ].includes(category) ? 'any' : '',
                bedrooms__gte: [ 'Residential' ].includes(category) ? '0' : '',
                bathrooms__gte: [ 'Residential' ].includes(category) ? '0' : '',
                parking_type__gte: [ 'Residential' ].includes(category) ? '0' : '',
                size_from: '',
                size_to: '',
                garden: '',
                security: '',
                pool: ''
              }
            ))
          })
        }

        const updateAdvanced = (additionalProps: SearchForm) => {
          setTimeout(() => {
            props.setValues(prevState => (
              {
                ...prevState,
                ...additionalProps
              }
            ))
          })
        }

        useEffect(() => {
          setTypeOptions(getIn(categoryTypeOptions, `${buy_rent}.${category}`))
          let cat = categoriesOptions.length ? categoriesOptions[0].value : 'Residential'
          if (category && categoriesOptions.find(c => c.value === category)) {
            cat = category
          }
          if (props.touched.category) {
            resetAdvanced({ locations: [] })
          } else if (props.touched.buy_rent) {
            resetAdvanced({ category: cat as string, locations: [] })
          }
        }, [ buy_rent, category, initialized ])

        useEffect(() => {
          if (!category || !buy_rent) { return }
          clearTimeout(propertyTimer)
          propertyTimer = setTimeout(() => {
            fetchPropertyTypes(values).then(response => response.data).then(propertyTypes => {
              setSelectedPropertyTypes(propertyTypes.map(pt => ({ value: pt, label: pt })))
            }).catch(e => console.error(e))
          }, 300)
          // @ts-ignore
        }, [ buy_rent, category, useCustomCompareMemo(locations), initialized ])

        useEffect(() => {
          setTypeOptions(getIn(categoryTypeOptions, `${buy_rent}.${category}`))
        }, [ useCustomCompareMemo(selectedPropertyTypes) ])

        useEffect(() => {
          const available = selectedPropertyTypes.map(pt => pt.value)
          updateAdvanced({ property_type__in: values.property_type__in?.filter(pt => available.includes(pt)) })
        }, [ useCustomCompareMemo(selectedPropertyTypes) ])

        useEffect(() => {
          if (!category || !buy_rent) { return }
          if (countTimer) {
            source.cancel()
            // eslint-disable-next-line import/no-named-as-default-member
            source = axios.CancelToken.source()
          }
          clearTimeout(countTimer)
          countTimer = setTimeout(() => {
            axios.post(api('/property-count/'), getPostData(props.values), { headers: { 'X-CSRFToken': csrftoken }, cancelToken: source.token }).then(resp => setPropertyCount(resp.data.count)).catch(e => console.error(e))
          }, 300)
        }, [
          useCustomCompareMemo(values)
        ])
        if (!initialized) {
          return null
        }
        return (
          <Form style={{ margin: 0 }}>
            <div className="search-main">
              <div className="search-main__default">
                {isMobile ? null : (
                  <>
                    <Field
                      component={SearchFormSelect}
                      name='buy_rent'
                      hideSelectedOptions={false}
                      options={buySelectOptions}
                    />
                    <Field
                      component={SearchFormSelect}
                      name='category'
                      icon={<HouseIcon />}
                      hideSelectedOptions={false}
                      options={categoriesOptions}
                    />
                    <div className="spacer_2" />
                  </>
                )}
                <Field
                  component={LocationSelect}
                  name='locations'
                  truncate={1}
                  icon={<PinIcon />}
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  placeholder="Search by Area, Suburb or Listing ID"
                  loadOptions={fetchLocations}
                  isClearable={false}
                  isMulti
                />
                <div className="spacer" />
                {isMobile ? null : (
                  <>
                    <Field
                      component={SearchFormSelect}
                      name='currency'
                      icon={<label className="input-field__label">Currency</label>}
                      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                      options={currencies.map(o => ({
                        value: o.code, label: o.code
                      }))}
                      isClearable={false}
                    />
                    <Field
                      component={InputField}
                      type={'number'}
                      label="Min"
                      name={'min_price'}
                      className={'input-field'}
                    />
                    <Field
                      component={InputField}
                      type={'number'}
                      label="Max"
                      name={'max_price'}
                      className={'input-field'}
                    />
                    <div className="spacer_2" />
                  </>
                )}
                <Button
                  variant={'white'}
                  onClick={() => {
                    setShowFilters(!showFilters)
                  }}>
                  <FiltersIcon />
                </Button>
                <Button
                  variant={propertyCount ? 'primary' : 'disabled'}
                  onClick={() => {
                    if (propertyCount) {
                      props.handleSubmit()
                    }
                  }}>
                SEARCH
                </Button>
              </div>
              <AnimateHeight
                className='search-main__filters'
                duration={300}
                height={showFilters ? 'auto' : 0}
              >
                <div className='search-main__filters__fields'>
                  {!isMobile ? null : (
                    <>
                      <Field
                        component={SearchFormSelect}
                        name='buy_rent'
                        hideSelectedOptions={false}
                        options={buySelectOptions}
                      />
                      <Field
                        component={SearchFormSelect}
                        name='category'
                        icon={<HouseIcon />}
                        hideSelectedOptions={false}
                        options={categoriesOptions}
                      />
                      <div className="spacer_2" />
                    </>
                  )}
                  {!isMobile ? null : (
                    <>
                      <Field
                        component={SearchFormSelect}
                        name='currency'
                        icon={<label className="input-field__label">Currency</label>}
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                        options={currencies.map(o => ({
                          value: o.code, label: o.code
                        }))}
                        isClearable={false}
                      />
                      <Field
                        component={InputField}
                        type={'number'}
                        label="Min"
                        name={'min_price'}
                        className={'input-field'}
                      />
                      <Field
                        component={InputField}
                        type={'number'}
                        label="Max"
                        name={'max_price'}
                        className={'input-field'}
                      />
                      <div className="spacer_2" />
                    </>
                  )}
                  {typeOptions?.map(group => <div className="search-main__group" key={group.name}>
                    {group.name ? <div className="search-main__group__label" dangerouslySetInnerHTML={{ __html: group.name }} /> : null}
                    {group.fields.map(field => (
                      <Field {...field} key={`${group.name}-${field.name}`} />
                    ))}
                  </div>)}
                </div>
                <div className="search-main__footer">
                  <div className="search-main__footer__buttons">
                    {is_filtered ? (
                      <Button variant='none' onClick={() => {
                        let resetVals = {}
                        typeOptions.forEach(group => group.fields.forEach(field => {
                          resetVals = setIn(
                            resetVals,
                            field.name,
                            ![ null, undefined ].includes(field.defaultValue) ? field.defaultValue : null
                          )
                        }))
                        resetAdvanced(resetVals)
                      }}>
                        <div className='icon'>
                          <CrossIcon />
                        </div>
                        Clear all filters
                      </Button>
                    ) : null}
                    <Button
                      variant={'white'}
                      onClick={() => {
                        setShowFilters(false)
                      }}>
                      <CloseFiltersIcon />
                    </Button>
                  </div>
                </div>
              </AnimateHeight>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
